import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#56B431",
      dark: "",
      contrastText: "white",
      light: "#abd997",
    },
    secondary: {
      main: "#0FA8C2",
      dark: "",
      contrastText: "white",
      light: "#9ad9e1",
    },
    error: {
      main: "#ED7E7E",
      light: "#f6bebe",
    },
    lightGray: {
      main: "#CCCCCC",
      dark:"#cecece",
      text:"#f6f6f6"
    },
    darkGray: {
      main: "#5A5A5A",
    },
    lightGreen:{
      main:'#99E080'
    }
  },
});

export default theme;
