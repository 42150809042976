import { Avatar } from "@mui/material";
import "./NavDrawer.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
const NavDrawer = ({ onHide, show, setShow }) => {
  return show ? (
    <div
      className="backdrop"
      onClick={() => {
        setShow && setShow(false);
        onHide && onHide();
      }}
    >
      <div className="drawer-wrapper">
        <div className="drawer-header">
          <Avatar />
          <div className="name-wrapper">
            <div>⽥中 太郎 </div>
            <div>tarotanaka@gmail.com</div>
          </div>
        </div>
        {[
          { name: "attendance record", link: "/attendance-record" },
          { name: "timecard", link: "/timecard" },
          { name: "task", link: "/tasklist" },
          { name: "calender", link: "/home" },
          { name: "message", link: "/messages" },
          { name: "notification", link: "/notification" },
          { name: "userprofile", link: "/home" },
        ].map((item, index) => {
          return (
            <div key={index} className="nav-drawer-item">
              <Link
                style={{ textDecoration: "none", color: "#5a5a5d" }}
                to={item.link}
              >
                <span>{item.name} </span>
              </Link>
              <NavigateNextIcon />
            </div>
          );
        })}
        <div className="nav-drawer-item">
          <Link style={{ textDecoration: "none", color: "#5a5a5d" }} to={"/"}>
            <span style={{ color: "red" }}>Logout </span>
          </Link>
          <NavigateNextIcon />
        </div>
      </div>
    </div>
  ) : null;
};

export default NavDrawer;
