import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import logoKyubo from "../../images/logo_kyubo.jpg";
import logoToffice from "../../images/logo_toffice.jpg";

import AccountCircle from "@mui/icons-material/AccountCircle";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/UserAuthContext";
import NavDrawer from "../NavDrawer/NavDrawer";

const pages = [
  // { name: "ホーム", link: "/notification" },
  // { name: "案件", link: "/attendance-record" },
  // { name: "勤怠", link: "/notification" },
  // { name: "カレンダー", link: "/business-report" },
  // { name: "メッセージ", link: "/messages" },
];

const ResponsiveAppBar = () => {
  const { logOut, user } = useUserAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    handleClose();
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const [show, setShow] = React.useState(false);

  const handleOpenNavMenu = (event) => {
    setShow(true);
  };

  const handleOpenNotifications = (event) => {};

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const [auth, setAuth] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = (e) => {
    setAuth(e.target.checked);
  };

  const handleMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <NavDrawer show={show} setShow={setShow} />
      <AppBar position="static" sx={{ boxShadow: 1 }}>
        <Container
          position="relative"
          maxWidth={false}
          sx={{ backgroundColor: "white" }}
        >
          <Toolbar disableGutters variant="dense">
            <Link to="/home" style={{ textDecoration: "none", color: "black" }}>
              <Box
                component="img"
                sx={{
                  height: 50,
                  width: 120,
                  maxHeight: { xs: 50, md: 50 },
                  maxWidth: { xs: 120, md: 120 },
                }}
                src={`${
                  process.env.REACT_APP_APPLICATION === "TOFFICE"
                    ? logoToffice
                    : logoKyubo
                }`}
              />
            </Link>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page, index) => (
                  <Link
                    key={index}
                    to={page.link}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                  </Link>
                ))}
              </Menu>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "end",
                mr: 5,
              }}
            >
              {pages.map((page, index) => (
                <Link
                  key={index}
                  to={page.link}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    color="darkGray"
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, mr: 2, display: "block" }}
                  >
                    {page.name}
                  </Button>
                </Link>
              ))}
              <Box
                sx={{
                  flexGrow: 0.1,
                  display: { xs: "none", md: "flex" },
                  borderRight: 1,
                  borderColor: "darkGray",
                  my: 2,
                }}
              />
            </Box>
            <Typography
              children={user.email}
              color="darkGray.main"
              sx={{
                mx: 2,
                display: { xs: "none", md: "flex" },
              }}
            />
            <Box sx={{ flexGrow: 0 }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="darkGray"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
              </Menu>
            </Box>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNotifications}
              color="darkGray"
              sx={{ display: { xs: "flex", md: "none" } }}
            >
              <NotificationsIcon />
            </IconButton>

            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="darkGray"
              sx={{ display: { xs: "flex", md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};
export default ResponsiveAppBar;
