import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../src/Pages/Home/Home";
import "./App.css";
import {
  UserAuthContextProvider
} from "./context/UserAuthContext";
import SignInSide from "./Pages/Login/SignInSide";
import ProtectedRoute from "./Utility/ProtectedRoute";

function App() {
  
  useEffect(() => {
  document.title =
    process.env.REACT_APP_APPLICATION === "TOFFICE"
      ? "T-Office | Admin"
      : "Kyubo | Admin";
  }, []);
  
  return (
    
    <div className="App">
      <UserAuthContextProvider>
        <Routes>
          <Route exact path="/" element={<SignInSide />} />
          <Route
            exact
            path="/home"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
        </Routes>
      </UserAuthContextProvider>
    </div>
  );
}

export default App;
