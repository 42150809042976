import * as React from "react";
import { Box, Button, Card, CardActions, CardContent, Paper, Typography } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import "./HomeCard.css";

export default function HomeCard({
  heading,
  url
}) {
  const toTitleCase = (str) => {
    return str
      .toLocaleLowerCase()
      .replace(
        /(^|Ü|ü|Ş|ş|Ç|ç|İ|ı|Ö|ö|\w)\S*/g,
        (txt) => txt.charAt(0).toLocaleUpperCase() + txt.substring(1)
      );
  };

  return (
    <Card sx={{ minWidth: 275 }} className="card">
      <CardContent>
        <Typography
          sx={{ fontSize: 14 }}
          color="text.secondary"
          gutterBottom
        ></Typography>
        <Typography variant="h5" component="div">
          {toTitleCase(heading.replace("_", " "))}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary"></Typography>
        <Typography variant="body2"></Typography>
      </CardContent>
      <CardActions>
        <a href={url} target="_blank">
          <Button size="small">開く</Button>
        </a>
      </CardActions>
    </Card>
  );
}
