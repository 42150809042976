import React from "react";
import AppBar from "../../Components/AppBar/AppBar";
import Grid from "@mui/material/Grid";
import HomeCard from "./HomeCard";
import { doc, getDoc } from "firebase/firestore";
import { useEffect } from "react";
import { useUserAuth } from "../../context/UserAuthContext";
import { db } from "../../config/firebase-config";
import { useState } from "react";
import Skeleton from "@mui/material/Skeleton";

const Home = () => {
  const { user } = useUserAuth();
  const [tenentData, setTenentData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    const docRef = doc(db, "tenant", user.email);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const obj = docSnap.data();
      const fetchedData = [];

      Object.keys(obj).map((key) => {
        fetchedData.push({
          url: obj[key][0],
          order: obj[key][1],
          name: obj[key][2],
        });
      });

      setTenentData(fetchedData.sort((a, b) => (a.order - b.order)));
      console.log(tenentData);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);

    getData();
  }, [user]);

  return (
    <div>
      <AppBar />
      <br />

      <Grid
        container
        display="flex"
        flexDirection="row"
        alignContent="center"
        justifyContent="center"
        maxHeight={true}
      >
        {loading && user ? (
          <>
            <Skeleton
              variant="rectangular"
              sx={{ minWidth: 275 }}
              className="card"
            />
            <Skeleton
              variant="rectangular"
              sx={{ minWidth: 275 }}
              className="card"
            />
            <Skeleton
              variant="rectangular"
              sx={{ minWidth: 275 }}
              className="card"
            />
            <Skeleton
              variant="rectangular"
              sx={{ minWidth: 275 }}
              className="card"
            />
            <Skeleton
              variant="rectangular"
              sx={{ minWidth: 275 }}
              className="card"
            />
          </>
        ) : (
          tenentData.map((item) => {
            return <HomeCard heading={item.name} url={item.url} />;
          })
        )}
      </Grid>
    </div>
  );
};
export default Home;
